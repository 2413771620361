/* eslint-disable no-unused-expressions */
import React from "react";
import * as Icon from "react-feather";
import moment from "moment";


const horizontalDynamicConfig = (allowedNavigations) => {
  let isMobile = window.innerWidth < 1200

  let topMenus = new Set(allowedNavigations.filter(item => item?.moduleId?.topModuleName).sort((x, y) => x?.moduleId?.topModuleId?.priority - y?.moduleId?.topModuleId?.priority).map((data) => data?.moduleId?.topModuleName));
  let parentMenus = new Set(
    allowedNavigations.map((data) => data?.moduleId?.parentModuleName)
  );
  let arrayList = [];

  Array.from(topMenus).map((item) => {
    let subname = [];
    allowedNavigations.map((sval) => {
      if (item === sval?.moduleId?.topModuleName) {
        subname.push({
          topName: item,
          subName: sval?.moduleId?.parentModuleName || sval?.moduleId?.name,
        });
      }
    });
    let test = [];
    subname.forEach((val) => {
      if (test.length > 0 && !test.includes(val.subName)) {
        test.push(val.subName);
      } else if (test.length === 0) {
        test.push(val.subName);
      }
    });
    arrayList.push({ TName: subname[0].topName, sName: test });
  });

  let array = [];
  arrayList.map((menu) => {
    let navChild = [];
    menu.sName.forEach((child) => {
      allowedNavigations.forEach((childData) => {
        if (child === childData?.moduleId?.parentModuleName) {
          let obj2 = [];
          Array.from(parentMenus).map((subVal) => {
            allowedNavigations.map((subdata) => {
              if (child === subdata?.moduleId?.parentModuleName) {
                const navObj2 = {
                  id: subdata?.moduleId?._id,
                  title: subdata?.moduleId?.name,
                  type: "item",
                  icon: <Icon.Circle size={10} />,
                  permissions: ["admin", "editor"],
                  navLink: subdata?.moduleId?.url,
                }
                if(subdata?.moduleId?.url === "/monthly-report"){
                  navObj2.navLink = `${subdata?.moduleId?.url}?month=${moment().get("month")}&year=${moment().get("year")}`
                } else if(subdata?.moduleId?.url === "/guests-list"){
                  navObj2.navLink = `${subdata?.moduleId?.url}/${moment().format("YYYY-MM-DD")}`
                } else {
                  navObj2.navLink = subdata?.moduleId?.url
                }
                obj2.push(navObj2);
              }
            });
          });

          const navChildObj = {
            id: child,
            title: child,
            type: obj2?.length ? isMobile ? "collapse" : "dropdown" : "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            priority: childData?.moduleId?.parentModuleId?.priority,
            children: [
              ...new Map(obj2.map((item) => [item.id, item])).values(),
            ],
          }

          navChild.push(navChildObj);
        } 
        if(child === childData?.moduleId?.name) {
          const navChildObj = {
            id: child,
            title: child,
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: childData?.moduleId?.url,
            priority: childData?.moduleId?.parentModuleId?.priority,
            children: [],
          }

          navChild.push(navChildObj);
        }
      });
    });

    array.push({
      id: menu.TName,
      title: menu.TName,
      type: isMobile ? "collapse" : "dropdown",
      icon: <Icon.Settings size={16} />,
      children: [
        ...new Map(navChild.map((item) => [item.title, item])).values(),
      ],
    });
  });
  return array;
};

export default horizontalDynamicConfig;
